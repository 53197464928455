import { PortableText } from '@portabletext/react'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'

import { routeMap } from '/routeMap'

import { HeadingXs, HeadingSm } from '/features/buildingBlocks/Heading'
import { Link } from '/features/buildingBlocks/Link'

import styles from './PortableTextComponent.css'

export function PortableTextComponent({ value, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <PortableText components={componentsMainContent} {...{ value }} />
    </div>
  )
}

export function PortableTextComponentSection({ value }) {
  return (
    <section data-x='text' className={styles.componentSection}>
      <PortableText components={componentsMainContent} {...{ value }} />
    </section>
  )
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
const componentsMainContent = {
  block: {
    normal: ({ children }) => <p className={styles.paragraph}>{children}</p>,
    heading: ({ children }) => <HeadingSm h='2' layoutClassName={styles.headingLayout} title={children} />,
    subheading: ({ children }) => <HeadingXs h='3' layoutClassName={styles.subheadingLayout} title={children} />,
  },
  list: {
    bullet: ({ children }) => <ul className={cx(styles.list, styles.listUnordered)}>{children}</ul>,
    number: ({ children }) => <ol className={cx(styles.list, styles.listOrdered)}>{children}</ol>,
  },
  listItem: (props) => <li className={styles.listItem}>{props.children}</li>,
  marks: {
    strong: (props) => <strong className={styles.strong}>{props.children}</strong>,
    em: (props) => <em className={styles.em}>{props.children}</em>,
    mailToLink: ({ value, children }) => <a href={`mailto:${value.href}`} data-x='link' className={styles.link}>{children}</a>,
    externalLink: ({ value, children }) => <a href={value.href} data-x='link' className={styles.link} target='_blank' rel="noreferrer">{children}</a>,
    internalLink: ({ value, children }) => value.ref
      ? <Link href={determineDocumentPathSync({ document: value.ref, routeMap })} label={children} dataX='link' />
      : <>{children}</>,
  },
}

// Dit component wordt gebruikt voor de xml feed
export function  PortableTextComponentCleanHtml({ blocks }) {
  return <PortableText value={blocks} components={componentsSimpleCleanHtml} />
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
const componentsSimpleCleanHtml = {
  list: {
    bullet: ({ children }) => <ul>{children}</ul>,
    number: ({ children }) => <ol>{children}</ol>,
  },
  listItem: (props) => <li>{props.children}</li>,
  marks: {
    strong: ({ children }) => <strong>{children}</strong>,
    em: ({ children }) => <em>{children}</em>,
    underlined: ({ children }) => <u>{children}</u>,
    internalLink: ({ value, children }) => <a href={determineDocumentPathSync({ document: value.ref, routeMap })}>{children}</a>,
    externalLink: ({ value, children }) => <a href={value.href}>{children}</a>,
  },
  block: {
    normal: ({ children }) => <p>{children}</p>,
    heading: ({ children }) => <h2>{children}</h2>,
    subheading: ({ children }) => <h3>{children}</h3>,
  },
}

export function portableTextToString(portableText) {
  if (!portableText) return ''

  return portableText
    .map(block => {
      if (block._type === 'block') {
        return block.children
          .map(child => child.text)
          .join('')
      }
      return ''
    })
    .join('\n\n')
}
